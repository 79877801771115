<template>
    <!-- 多媒体消息管理 -->
    <div id="app_media_id">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_title">多媒体消息</div>
        </el-row>

        <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            <el-row style="overflow: auto;" :style="{height: ((content_heigth)+'px')}">
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini">
                    <el-table-column prop="channel_id" label="通道id" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="channel_name" label="通道名称" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="channelCompanyName" label="通道公司名称" min-width="80" align="center"> </el-table-column>
                    <el-table-column label="管理" min-width="80" align="center">
                        <template slot-scope="scope">
                            <template v-if="scope.row.channel_home == '浙江启方'">
                                <el-button type="text" size="mini" @click="template_qf(scope.row.channel_id)">模板管理</el-button>
                            </template>
                            <template v-else-if="scope.row.channel_home == '北京修治'">
                                <el-button type="text" size="mini" @click="sign_xz(scope.row.channel_id)">签名管理</el-button>
                                <el-button type="text" size="mini" @click="template_xz(scope.row.channel_id)">模板管理</el-button>
                            </template>
                            <template v-else-if="scope.row.channel_home == '上海移动直连'">
                                <el-button type="text" size="mini" @click="sign_sh(scope.row.channel_id)">签名管理</el-button>
                                <el-button type="text" size="mini" @click="template_sh(scope.row.channel_id)">模板管理</el-button>
                            </template>
                            <template v-else-if="scope.row.channel_home == '中国联通数字短信平台'">
                                <el-button type="text" size="mini" @click="sign_cucc(scope.row.channel_id)">签名管理</el-button>
                                <el-button type="text" size="mini" @click="template_cucc(scope.row.channel_id)">模板管理</el-button>
                            </template>
                            <template v-else-if="scope.row.channel_home == 'Thunder 5G SMS'">
                                <el-button type="text" size="mini" @click="sign_thunder(scope.row.channel_id)">签名管理</el-button>
                                <el-button type="text" size="mini" @click="template_thunder(scope.row.channel_id)">模板管理</el-button>
                            </template>
                            <template v-else-if="scope.row.channel_home == '浙江移动直连'">
                                <el-button type="text" size="mini" @click="sign_zj(scope.row.channel_id)">签名管理</el-button>
                                <el-button type="text" size="mini" @click="template_zj(scope.row.channel_id)">模板管理</el-button>
                            </template>
                            <template v-else>
                                <el-button type="text" size="mini" @click="sign(scope.row.channel_id)">签名管理</el-button>
                                <el-button type="text" size="mini" @click="template(scope.row.channel_id)">模板管理</el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
        </el-row>
    </div>
</template>
<script>
import API from '../../api/api';

export default {

    components: {
        
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            tableData:[],//表格数据
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();
        
        //查询
        this.getData();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);
        },
        //查询
        getData(){
            //请求接口
            API.ChannelServlet({
                param: "mediaListNoPage",
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;

                    this.$nextTick(function(){
                        //获取分页的高度
                        var pagination = document.getElementById('pagination_id');
                        if(!API.isEmtry(pagination)){
                            this.pagination_height = pagination.clientHeight;
                        }
                    });
                } 
            });
        },
        //返回
        goBack() {
            API.router_to("/index");
        },
        //签名管理
        sign(channel_id) {
            API.router_to("/media_sign/"+channel_id);
        },
        //模板管理
        template(channel_id) {
            API.router_to("/media_template/"+channel_id);
        },
        //浙江启方模板管理
        template_qf(channel_id) {
            API.router_to("/media_template_qf/"+channel_id);
        },
        //北京修治签名管理
        sign_xz(channel_id) {
            API.router_to("/media_sign_xz/"+channel_id);
        },
        //北京修治模板管理
        template_xz(channel_id) {
            API.router_to("/media_template_xz/"+channel_id);
        },
        //上海移动直连签名管理
        sign_sh(channel_id) {
            API.router_to("/media_sign_sh/"+channel_id);
        },
        //上海移动直连模板管理
        template_sh(channel_id) {
            API.router_to("/media_template_sh/"+channel_id);
        },
        //中国联通数字短信平台签名管理
        sign_cucc(channel_id) {
            API.router_to("/media_sign_cucc/"+channel_id);
        },
        //中国联通数字短信平台模板管理
        template_cucc(channel_id) {
            API.router_to("/media_template_cucc/"+channel_id);
        },
        //Thunder 5G SMS签名管理
        sign_thunder(channel_id) {
            API.router_to("/media_sign_thunder/"+channel_id);
        },
        //Thunder 5G SMS模板管理
        template_thunder(channel_id) {
            API.router_to("/media_template_thunder/"+channel_id);
        },
        //浙江移动直连签名管理
        sign_zj(channel_id) {
            API.router_to("/media_sign_zj/"+channel_id);
        },
        //浙江移动直连模板管理
        template_zj(channel_id) {
            API.router_to("/media_template_zj/"+channel_id);
        },
    }
};
</script>

<style scoped>
/*form表单样式调整*/
#app_check_rate_list>>>.el-form-item {
  margin-bottom: 0px;
}
</style>